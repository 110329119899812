import widgetManager from '../common/widgetManager';
import { setLocalWithExpiry } from '../utils/utils';

function generateWidgets() {
    let allTag = document.querySelectorAll('a');
    for (let tag of allTag) {
        if (typeof tag.className !== 'string') {
            continue;
        }

        if(tag.className.indexOf('weezevent-widget-integration') !== -1) {
            widgetManager.fromTag(tag);
        }
    }
}

function saveRelevantParams() {
    let urlParams = new URLSearchParams(window.location.search);
    // Go through each URLparams & save them in localStorage
    for (const [key, value] of urlParams.entries()) {
        setLocalWithExpiry(key, value);
    }
}

function listenToDOM() {
    const observer = new window.MutationObserver((mutations) => {
        let anyNew = mutations.some(mutation => mutation.type === 'childList');

        if(!anyNew) {
            return;
        }

        generateWidgets();
    });

    observer.observe(document.body, {
        attributes: false,
        childList: true,
        subtree: true
    });
}

// Make first pass after DOM generation
generateWidgets();

// Listen for DOM changes on document.body
listenToDOM();

// Store UTM markers & origin
saveRelevantParams();
