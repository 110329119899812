import Widget from '../common/widget';
import { resizeHandle, unknownHandle } from './handlers';
import WeezSocket from '../common/weezSocket';
import { oldBindEvent } from './oldWeez';
import { getSourceWithParentParams } from '../utils/utils';

export default class WidgetNeo extends Widget {
    static type = 'neo';
    static handles = [
        unknownHandle,
        resizeHandle,
    ];

    getAttributes() {
        const data = this.getDataset();
        const src = getSourceWithParentParams(data.src, {
            neo: '1'
        });

        return {
            'src': src,
            'height': data.height,
            'resize': data.resize,
            'width': (data.width_auto && data.width_auto === '1') ? '100%' : data.width,
            'frameborder': '0',
            'allowfullscreen': 'true',
            'scrolling': 'auto',
            'hidePoweredBy': (data.nopb) ? data.nopb : 0,
        };
    }

    /**
     * Display tag "Powered By Weezevent " if data-nopb= 1 in the integration tag
     */
    injectPoweredByWeezevent() {
        let weezLink = document.createElement('a');
        this.styleLink(weezLink);
        weezLink.innerHTML = 'Powered by Weezevent';

        // <a data-nopb="1"> hides the powered by Weezevent
        if (this.getDataset().nopb === '1') {
            weezLink.style.display = 'none';
        }
        this.getContainer().appendChild(weezLink);
    }

    /**
     * Style of the long Powered By Weezevent
     */
    styleLink(weezLink) {
        let css = this.tag.style.cssText;
        css += 'float: right !important;';
        css += 'margin-left: auto !important;';
        css += 'margin-top: 10px !important;';
        css += 'font-size: 11px !important;';
        css += 'font-family: Arial, sans-serif !important;';
        css += 'text-decoration: none !important;';
        css += 'color: #333333 !important;';
        weezLink.style.cssText = css;
        weezLink.href = this.tag.href;
    }

    render() {
        super.render();
        oldBindEvent({ resize: this.getDataset().resize }, this.iframe);
        this.socket = new WeezSocket(this.iframe);
        this.injectPoweredByWeezevent();
    }
}
