import Message from './message';
import {needHackForChrome108} from '../utils/utils';

export default class Widget {
    // A list of instance of Handle
    // They will be connected to the event listener
    static handles = [];

    constructor(tag) {
        // the <a> tag triggering the widget
        this.tag = tag;
        // The element containing the iframe
        this.container = null;

        // The active handle, filled by Widget.on
        this.handlers = {};

        // The iframe reference, filled by makeIframe
        this.iframe = null;
    }

    // Register the listeners
    registerHandles() {
        // Get the handles from the instance or the class else
        const handles = this.handles || this.constructor.handles;
        // Register all given handles
        for(let handle of handles) {
            // Register on master handler + pass on socket for future usage
            this.on(handle.type, handle.callback);
        }

        window.addEventListener('message', (event) => {
            // filter messages directed to this iframe
            if(event.source === this.iframe.contentWindow) {
                let message = Message.fromEvent(event);
                if (process.env.NODE_ENV === 'development') {
                    console.debug('Received message', message);
                }
                this.handle(message);
            }
        });
    }

    // Add a message listener for `messageType`
    on(messageType, handler) {
        this.handlers[messageType] = this.handlers[messageType] || [];
        this.handlers[messageType].push(handler);
    }

    // Handle a message coming on the widget
    handle(message) {
        if(!this.handlers[message.type]) {
            if (process.env.NODE_ENV === 'development') {
                console.info('No handler for type', message.type);
            }
            return;
        }

        for(const handler of this.handlers[message.type]) {
            handler(message, this);
        }
    }

    // A compatibility mode where a div is injected between the element
    // containing the <a> tag and the <iframe>
    shouldUseContainer() {
        return this.tag.parentNode.tagName.toLowerCase() !== 'div' || this.getDataset().useContainer || needHackForChrome108();
    }

    // Returns the dataset of the tag
    getDataset() {
        return this.tag.dataset || {};
    }

    // Get the element in which the iframe is injected
    getContainer() {
        // if we already created a container for this widget, reuse it
        if (this.container) {
            return this.container;
        }

        // Inject the iframe in the same container as the `a` tag
        const container = this.tag.parentNode;

        if (!this.shouldUseContainer()) {
            // inject directly in the parent of the A
            this.container = container;
            return container;
        }

        // Retrocomp
        // first inject a div[display=flex] between the iframe and its parent
        // and then inject the iframe in this div
        this.container = document.createElement('div');
        this.container.style.display = 'flex';
        this.container.style.flexDirection = 'column';
        this.container.style.alignItems = 'center';

        // Add a max height on the widget's container div, to enable scrolling on the widget.
        // This works around a bug introduced in chromium 108
        // https://support.google.com/chrome/thread/191480215/chrome-108-iframe-problem?hl=en
        if (needHackForChrome108()) {
            this.container.style.maxHeight = '100vh';
        }

        container.appendChild(this.container);
        return this.container;
    }

    // Render the widget by creating the iframe, appending it to the container
    // and removing the original <a> tag
    render() {
        this.iframe = this.buildIframe();
        this.inject();
        this.prune();
    }

    // Get the attributes of the iframe
    getAttributes() {
        return {
            // src of the iframe is data-src or the href of the tag
            src: this.getDataset().src || this.tag.href,
        };
    }

    // Create a new iframe and set the attributes
    buildIframe() {
        const iframe = document.createElement('iframe');
        const attributes = this.getAttributes();
        for (let key in attributes) {
            if (!attributes[key]) {
                continue;
            }
            iframe.setAttribute(key, attributes[key]);
        }
        return iframe;
    }

    inject() {
        this.getContainer().appendChild(this.iframe);

        // Register handles for window.postMessage
        this.registerHandles();
        /* TODO : get handler callback ok to remove attribute */
    }

    prune() {
        // Remove the link from the DOM
        this.tag.parentNode.removeChild(this.tag);
    }
}
